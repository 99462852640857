import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { Usuario } from "../../app/sdk/@types";
import UsuarioService from "../../app/sdk/services/Usuario.service";

interface UsuarioState {
  fetching: boolean;
  list: Usuario.PageUsuarioResponse;
}

const initialState: UsuarioState = {
  fetching: false,
  list: {},
};

export const getAllUsuarios = createAsyncThunk(
  "usuario/getAllUsuarios",
  async (query: Usuario.Query) => UsuarioService.getAll(query)
);

export const alternarStatusDoUsuario = createAsyncThunk(
  "usuario/alternarStatusDoUsuario",
  async (usuario: Usuario.UsuarioResponse) => {
    usuario.ativo
      ? await UsuarioService.desativar(Number(usuario.id))
      : await UsuarioService.ativar(Number(usuario.id));
    return usuario;
  }
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(getAllUsuarios.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addMatcher(isPending(getAllUsuarios, alternarStatusDoUsuario), (state) => {
      state.fetching = true;
    })
    .addMatcher(
      isFulfilled(getAllUsuarios, alternarStatusDoUsuario),
      (state) => {
        state.fetching = false;
      }
    )
    .addMatcher(
      isRejected(getAllUsuarios, alternarStatusDoUsuario),
      (state) => {
        state.fetching = false;
      }
    );
});
