import { Col, Row } from "antd";
import usePageTitle from "../../core/hooks/usePageTitle";
import ProdutoLista from "../features/ProdutoLista";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function ProdutoListaView() {
  usePageTitle("Consulta de Produto");
  useBreadcrumb("Produto/Consulta");

  return (
    <Row>
      <Col xs={24}>
        <ProdutoLista />
      </Col>
    </Row>
  );
}
