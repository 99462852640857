import { Menu, Layout, theme, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  IdcardOutlined,
  PlusCircleOutlined,
  ProductOutlined,
  SolutionOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useState } from "react";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export default function DefaultLayoutSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItens: MenuItem[] = [
    {
      key: "/",
      label: "Início",
      icon: <HomeOutlined />,
      onClick: () => navigate("/"),
    },
    {
      key: "locacao",
      label: "Locação",
      icon: <SolutionOutlined />,
      children: [
        {
          key: "/locacao",
          label: "Consulta",
          icon: <TableOutlined />,
          onClick: () => navigate("/locacao"),
        },
        {
          key: "/locacao/cadastro",
          label: "Cadastro",
          icon: <PlusCircleOutlined />,
          onClick: () => navigate("/locacao/cadastro"),
        },
      ],
    },
    {
      key: "cliente",
      label: "Cliente",
      icon: <IdcardOutlined />,
      children: [
        {
          key: "/cliente",
          label: "Consulta",
          icon: <TableOutlined />,
          onClick: () => navigate("/cliente"),
        },
        {
          key: "/cliente/cadastro",
          label: "Cadastro",
          icon: <PlusCircleOutlined />,
          onClick: () => navigate("/cliente/cadastro"),
        },
      ],
    },
    {
      key: "produto",
      label: "Produto",
      icon: <ProductOutlined />,
      children: [
        {
          key: "/produto",
          label: "Consulta",
          icon: <TableOutlined />,
          onClick: () => navigate("/produto"),
        },
        {
          key: "/produto/cadastro",
          label: "Cadastro",
          icon: <PlusCircleOutlined />,
          onClick: () => navigate("/produto/cadastro"),
        },
      ],
    },
    {
      key: "usuario",
      label: "Usuário",
      icon: <UserOutlined />,
      children: [
        {
          key: "/usuario",
          label: "Consulta",
          icon: <TableOutlined />,
          onClick: () => navigate("/usuario"),
        },
        {
          key: "/usuario/cadastro",
          label: "Cadastro",
          icon: <PlusCircleOutlined />,
          onClick: () => navigate("/usuario/cadastro"),
        },
      ],
    },
    {
      type: "divider",
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [broken, setBroken] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  function handleMenuClick() {
    if (broken && !collapsed) {
      setCollapsed(!collapsed);
    }
  }

  return (
    <Sider
      className="no-print"
      style={{
        background: colorBgContainer,
        boxShadow:
          "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
      }}
      breakpoint="lg"
      collapsedWidth="0"
      zeroWidthTriggerStyle={{
        backgroundColor: "transparent",
        top: "-64px",
        left: 0,
        height: "64px",
        borderRadius: 0,
        display: "Flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      collapsible
      collapsed={collapsed}
      onBreakpoint={setBroken}
      onCollapse={setCollapsed}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={[location.pathname.split("/")[1]]}
        items={menuItens}
        onClick={handleMenuClick}
      />
    </Sider>
  );
}
