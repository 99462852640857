import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import * as OrdemLocacaoActions from "../store/OrdemLocacao.reducer";

export default function useOrdensLocacao() {
  const dispatch = useDispatch<any>();
  const fetching = useSelector(
    (state: RootState) => state.ordemLocacao.fetching
  );
  const ordensLocacao = useSelector((state: RootState) => state.ordemLocacao);

  const fetchOrdensLocacao = useCallback(async () => {
    await dispatch(OrdemLocacaoActions.buscarTodasOrdensLocacao());
  }, [dispatch]);

  return {
    ordensLocacao,
    fetching,
    fetchOrdensLocacao,
  };
}
