import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { Cliente } from "../../app/sdk/@types";
import * as ClienteActions from "../store/Cliente.reducer";

export default function useClientes() {
  const dispatch = useDispatch<any>();
  const fetching = useSelector((state: RootState) => state.cliente.fetching);
  const clientes = useSelector((state: RootState) => state.cliente);

  const paginacao = (page: number): Cliente.Query => {
    return {
      page,
      size: 15,
      showAll: true,
      sort: ["nome", "asc"],
    };
  };

  const fetchClientes = useCallback(
    async (page: number) => {
      await dispatch(ClienteActions.buscarTodosClientes(paginacao(page)));
    },
    [dispatch]
  );

  const alternarStatusDoCliente = useCallback(
    async (cliente: Cliente.ClienteResponse, page: number) => {
      await dispatch(ClienteActions.alternarStatusDoCliente(cliente));
      await dispatch(ClienteActions.buscarTodosClientes(paginacao(page)));
    },
    [dispatch]
  );

  return {
    clientes,
    fetching,
    fetchClientes,
    alternarStatusDoCliente,
  };
}
