import { Breadcrumb as DefaultLayoutBreadcrumb } from "antd";
import useBreadcrumb from "../../../core/hooks/useBreadcrumb";

export default function Breadcrumb() {
  const { breadcrumb } = useBreadcrumb();
  const breadcrumbList = breadcrumb.map((item) => ({ title: item }));
  return (
    <DefaultLayoutBreadcrumb
      style={{ margin: "15px 0" }}
      items={breadcrumbList}
    />
  );
}
