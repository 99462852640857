import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Tooltip } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { formatDataHora } from "../sdk/utils/data";
import { OrdemLocacao } from "../sdk/@types";
import useOrdensLocacao from "../../core/hooks/useOrdensLocacao";
import renderBadge from "../sdk/utils/getBadges";

export default function OrdemLocacaoLista() {
  const { ordensLocacao, fetching, fetchOrdensLocacao } = useOrdensLocacao();

  useEffect(() => {
    fetchOrdensLocacao();
  }, [fetchOrdensLocacao]);

  return (
    <>
      <Table<OrdemLocacao.OrdemLocacaoResponse>
        dataSource={ordensLocacao.list}
        rowKey={"id"}
        loading={fetching}
        pagination={{
          pageSize: 10,
        }}
        scroll={{ x: 600 }}
        size="small"
        bordered
        columns={[
          {
            dataIndex: "id",
            title: "ID",
            align: "center",
            width: 60,
          },
          {
            dataIndex: ["cliente", "nome"],
            title: "Cliente",
            fixed: "left",
            width: 180,
          },
          {
            dataIndex: "status",
            title: "Status",
            align: "center",
            width: 100,
            render: (item) => {
              return <Space align="center" size="small">{renderBadge(item)}</Space>;
            },
          },
          {
            dataIndex: "frequenciaLocacao",
            title: "Frequencia de Locação",
            align: "center",
            width: 100,
            render: (item, record) => `${item} (${record.quantidadeDias} dias)`,
          },
          {
            dataIndex: "dataInicio",
            title: "Data Início",
            align: "center",
            width: 130,
            render: formatDataHora,
          },
          {
            dataIndex: "dataFim",
            title: "Data Fim",
            align: "center",
            width: 130,
            render: formatDataHora,
          },
          {
            dataIndex: "dataFimExtendida",
            title: "Data Fim Extendida",
            align: "center",
            width: 130,
            render: formatDataHora,
          },
          {
            dataIndex: "id",
            title: "Ações",
            align: "center",
            width: 100,
            render(id: number) {
              return (
                <Space>
                  <Tooltip title="Visualizar Ordem de Locação" placement="left">
                    <Link to={`/locacao/${id}`}>
                      <Button size="small" icon={<EyeOutlined />} />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Editar Ordem de Locação" placement="right">
                    <Link to={`/locacao/edicao/${id}`}>
                      <Button size="small" icon={<EditOutlined />} />
                    </Link>
                  </Tooltip>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
}
