import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Switch, Table, Tooltip } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { formatDataHora } from "../sdk/utils/data";
import { Cliente } from "../sdk/@types";
import useClientes from "../../core/hooks/useClientes";
import formatCpfCnpj from "../sdk/utils/formatCpfCnpj";
import formatTelefone from "../sdk/utils/formatTelefone";

export default function ClienteLista() {
  const { clientes, fetching, fetchClientes, alternarStatusDoCliente } =
    useClientes();
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchClientes(page);
  }, [fetchClientes, page]);

  return (
    <>
      <Table<Cliente.ClienteResponse>
        dataSource={clientes.list.content}
        rowKey={"id"}
        loading={fetching}
        pagination={{
          onChange: (page) => setPage(page - 1),
          total: clientes.list.totalElements,
          pageSize: clientes.list.size,
        }}
        scroll={{ x: 600 }}
        size="small"
        bordered
        columns={[
          {
            dataIndex: "id",
            title: "ID",
            align: "center",
            width: "5%",
          },
          {
            dataIndex: "nome",
            title: "Nome",
            fixed: "left",
          },
          {
            dataIndex: "cpfCnpj",
            title: "CPF / CNPJ",
            align: "center",
            render: formatCpfCnpj,
          },
          {
            dataIndex: "telefone",
            title: "Telefone",
            align: "center",
            render: formatTelefone,
          },
          {
            dataIndex: "celular",
            title: "Celular",
            align: "center",
            render: formatTelefone,
          },
          {
            dataIndex: "dataCadastro",
            title: "Data do Cadastro",
            align: "center",
            render: formatDataHora,
          },
          {
            dataIndex: "ativo",
            title: "Ativo",
            align: "center",
            render(ativo: boolean, cliente) {
              return (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={() => {
                    alternarStatusDoCliente(cliente, page);
                  }}
                  checked={ativo}
                />
              );
            },
          },
          {
            dataIndex: "id",
            title: "Ações",
            align: "center",
            render(id: number) {
              return (
                <Space>
                  <Tooltip title="Visualizar cliente" placement="left">
                    <Link to={`/cliente/${id}`}>
                      <Button size="small" icon={<EyeOutlined />} />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Editar cliente" placement="right">
                    <Link to={`/cliente/edicao/${id}`}>
                      <Button size="small" icon={<EditOutlined />} />
                    </Link>
                  </Tooltip>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
}
