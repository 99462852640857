import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { AxiosError } from "axios";
import { Erros, ErrorResponse } from "../sdk/@types/ErrosApi";
import { OrdemLocacao } from "../sdk/@types";
import OrdemLocacaoService from "../sdk/services/OrdemLocacao.service";
import ClienteComboBoxComponent from "../components/ClienteComboBoxComponent";
import ItensDeLocacaoComponent from "../components/ItensDeLocacaoComponent";

interface OrdemLocacaoFormProps {
  ordemLocacao?: OrdemLocacao.OrdemLocacaoResponse;
  onUpdate?: (ordemLocacao: OrdemLocacao.OrdemLocacaoRequest) => Promise<any>;
}

export default function OrdemLocacaoForm(props: OrdemLocacaoFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isQuantidadeDiasDisabled, setIsQuantidadeDiasDisabled] =
    useState(false);
  const [form] = Form.useForm<OrdemLocacao.OrdemLocacaoRequest>();

  const frequenciaLocacao: Record<
    string,
    { descricao: string; quantidade: number | undefined }
  > = useMemo(
    () => ({
      DIA: { descricao: "Dia", quantidade: undefined },
      SEMANA: { descricao: "Semana", quantidade: 5 },
      QUINZENA: { descricao: "Quinzena", quantidade: 15 },
      MES: { descricao: "Mês", quantidade: 30 },
    }),
    []
  );

  useEffect(() => {
    form.setFieldsValue({ quantidadeDias: undefined });
    setIsQuantidadeDiasDisabled(false);
  }, [form]);

  const handleFrequenciaLocacaoChange = (value: string) => {
    if (value !== "DIA") {
      const quantidade = frequenciaLocacao[value]?.quantidade;
      form.setFieldsValue({ quantidadeDias: quantidade });
      setIsQuantidadeDiasDisabled(true);
    } else {
      form.setFieldsValue({ quantidadeDias: undefined });
      setIsQuantidadeDiasDisabled(false);
      setTimeout(() => {
        form.getFieldInstance("quantidadeDias")?.focus();
      }, 0);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (ordemLocacao) => {
        setLoading(true);

        console.log(ordemLocacao);
        if (props.ordemLocacao)
          return (
            props.onUpdate &&
            props.onUpdate(ordemLocacao).finally(() => {
              setLoading(false);
            })
          );

        try {
          console.log(ordemLocacao);
          await OrdemLocacaoService.insert(ordemLocacao);
          navigate("/locacao");
          notification.success({
            message: "Sucesso",
            description: "Ordem de Locação cadastrada com sucesso",
          });
        } catch (reason) {
          if (reason instanceof AxiosError) {
            const data = reason.response?.data as Erros | ErrorResponse;
            console.error(data);
            // form.setFields(data.)
          } else {
            notification.error({
              message: "Ocorreu um erro",
            });
          }
        } finally {
          setLoading(false);
        }
      }}
      initialValues={props.ordemLocacao}
    >
      <Row gutter={24} align="middle">
        <Col xs={24} style={{ marginBottom: 18 }}>
          <Typography.Title level={4}>Ordem de Locação</Typography.Title>
        </Col>

        <Col xs={24} lg={14}>
          <Form.Item
            label="Cliente"
            name="idCliente"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <ClienteComboBoxComponent />
          </Form.Item>
        </Col>

        <Col xs={24} lg={5}>
          <Form.Item
            label="Frequência da Locação"
            name="frequenciaLocacao"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Select
              placeholder="Selecione..."
              onChange={handleFrequenciaLocacaoChange}
            >
              {Object.keys(frequenciaLocacao).map((chave) => (
                <Select.Option key={chave} value={chave}>
                  {frequenciaLocacao[chave].descricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} lg={5}>
          <Form.Item
            label="Quantidade de Dias"
            name="quantidadeDias"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <InputNumber
              min={1}
              max={30}
              style={{ width: "100%" }}
              placeholder="Quantidade de Dias"
              disabled={isQuantidadeDiasDisabled}
            />
          </Form.Item>
        </Col>

        <Divider />

        <Col xs={24}>
          <ItensDeLocacaoComponent form={form} />
        </Col>

        <Divider />

        <Col xs={24}>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {props.ordemLocacao
                ? "Atualizar Ordem de Locação"
                : "Cadastrar Ordem de Locação"}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
