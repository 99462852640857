import { Produto } from "../../sdk/@types";
import Service from "../Service";

class ProdutoService extends Service {
  static getComboBox() {
    return this.Http.get<Produto.ComboBox[]>("/produto/combobox").then(
      this.getData
    );
  }

  static findById(id: number) {
    return this.Http.get<Produto.ProdutoResponse>(`/produto/${id}`).then(
      this.getData
    );
  }

  static findAll() {
    return this.Http.get<Produto.ProdutoResponse[]>("/produto").then(
      this.getData
    );
  }

  static insert(produto: Produto.ProdutoRequest) {
    return this.Http.post<Produto.ProdutoResponse>("/produto", produto).then(
      this.getData
    );
  }

  static update(idProduto: number, produto: Produto.ProdutoRequest) {
    return this.Http.put<Produto.ProdutoResponse>(
      `/produto/${idProduto}`,
      produto
    ).then(this.getData);
  }

  static deleteProduto(id: number) {
    return this.Http.delete(`/produto/${id}`);
  }
}

export default ProdutoService;
