import useBreadcrumb from "../../core/hooks/useBreadcrumb";
import usePageTitle from "../../core/hooks/usePageTitle";
import ProdutoForm from "../features/ProdutoForm";

export default function ProdutoCadastroView() {
  usePageTitle("Cadastro de produto");
  useBreadcrumb("Produto/Cadastro");

  return <ProdutoForm />;
}
