import { Table, Tag } from "antd";
import { OrdemLocacao } from "../sdk/@types";
import { formatDataHora } from "../sdk/utils/data";
import { ArrowRightOutlined } from "@ant-design/icons";

interface RegistroMovimentacaoReadOnlyProps {
  registroMovimento?: OrdemLocacao.RegistroMovimentoResponse[];
  quantidadeDias?: number;
}

export default function RegistroMovimentacaoReadOnly(
  props: RegistroMovimentacaoReadOnlyProps
) {
  return (
    <Table<OrdemLocacao.RegistroMovimentoResponse>
      size="small"
      dataSource={props.registroMovimento}
      pagination={false}
      rowKey="id"
      bordered
      scroll={{ x: 600 }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "index",
          rowScope: "row",
          align: "center",
          width: "5%",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Data do Movimento",
          dataIndex: "dataMovimento",
          key: "dataMovimento",
          align: "center",
          render: (
            dataMovimento: number,
            item: OrdemLocacao.RegistroMovimentoResponse
          ) => formatDataHora(dataMovimento),
        },
        {
          title: "Tipo do Movimento",
          dataIndex: "tipoMovimento",
          key: "tipoMovimento",
          align: "center",
          render: (tipoMovimento: string) => (
            <Tag color={tipoMovimento === "RETIRADA" ? "warning" : "green"}>
              {tipoMovimento === "DEVOLUCAO" ? "DEVOLUÇÃO" : "RETIRADA"}
            </Tag>
          ),
        },
        {
          title: "Quantidade",
          key: "quantidade",
          align: "center",
          render: (text, record) => (
            <>
              {record.quantidadeLocacao} {record.unidade?.toLowerCase()}(s){" "}
              <ArrowRightOutlined /> {record.quantidadePeca} peças
            </>
          ),
        },
        {
          title: "Produto",
          dataIndex: "nomeProduto",
          key: "nomeProduto",
        },
      ]}
    />
  );
}
