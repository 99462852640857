import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { Produto } from "../../app/sdk/@types";
import ProdutoService from "../../app/sdk/services/Produto.service";

interface ProdutoState {
  fetching: boolean;
  list: Produto.ProdutoResponse[];
}

const initialState: ProdutoState = {
  fetching: false,
  list: [],
};

export const buscarTodosProdutos = createAsyncThunk(
  "produto/buscarTodosProdutos",
  async () => ProdutoService.findAll()
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(buscarTodosProdutos.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addMatcher(isPending(buscarTodosProdutos), (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled(buscarTodosProdutos), (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected(buscarTodosProdutos), (state) => {
      state.fetching = false;
    });
});
