import { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import { formatDataHora } from "../sdk/utils/data";
import { OrdemLocacao } from "../sdk/@types";
import {
  FrequenciaLocacao,
  FrequenciaLocacaoProps,
} from "../sdk/utils/locacao";
import formatCpfCnpj from "../sdk/utils/formatCpfCnpj";

interface OrdemLocacaoReadOnlyProps {
  ordemLocacao?: OrdemLocacao.OrdemLocacaoResponse;
}

export default function OrdemLocacaoReadOnly(props: OrdemLocacaoReadOnlyProps) {
  const [frequenciaLocacao, setFrenquenciaLocacao] =
    useState<FrequenciaLocacaoProps>();

  useEffect(() => {
    if (props.ordemLocacao) {
      setFrenquenciaLocacao(
        FrequenciaLocacao(props.ordemLocacao?.frequenciaLocacao)
      );
    }
  }, [props.ordemLocacao]);

  return (
    <Form layout="vertical">
      <Row gutter={24} align="middle">
        <Col xs={24} lg={8}>
          <Form.Item label="ID">
            <Input value={props.ordemLocacao?.id || ""} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label="Status">
            <Input value={props.ordemLocacao?.status || ""} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label="Data Emissão">
            <Input
              value={formatDataHora(props.ordemLocacao?.dataEmissao || "")}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label="Nome do Cliente">
            <Input value={props.ordemLocacao?.cliente?.nome || ""} disabled />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label="CPF / CNPJ">
            <Input
              value={formatCpfCnpj(props.ordemLocacao?.cliente?.cpfCnpj)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label="Frequência de Locação">
            <Input
              value={
                frequenciaLocacao
                  ? frequenciaLocacao?.descricao
                  : props.ordemLocacao?.frequenciaLocacao || ""
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label="Período">
            <Input
              value={
                frequenciaLocacao?.quantidade ||
                props.ordemLocacao?.quantidadeDias
                  ? (frequenciaLocacao?.quantidade ||
                      props.ordemLocacao?.quantidadeDias) + " dias"
                  : ""
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label="Data Início">
            <Input
              value={formatDataHora(props.ordemLocacao?.dataInicio)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label="Data Fim">
            <Input
              value={formatDataHora(props.ordemLocacao?.dataFim)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item label="Data Fim Extendida">
            <Input
              value={formatDataHora(props.ordemLocacao?.dataFimExtendida)}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
