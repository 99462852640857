import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Card, Skeleton, notification } from "antd";
import { Produto } from "../sdk/@types";
import usePageTitle from "../../core/hooks/usePageTitle";
import ProdutoService from "../sdk/services/Produto.service";
import ProdutoForm from "../features/ProdutoForm";
import useProduto from "../../core/hooks/useProduto";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function ProdutoEdicaoView() {
  usePageTitle("Edição de produto");
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { produto, notFound, fetchProduto } = useProduto();
  useBreadcrumb(`Produto/Edição/${produto?.nome || produto?.id}`);

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchProduto(Number(params.id));
  }, [fetchProduto, params.id]);

  if (isNaN(Number(params.id))) return <Navigate to="/produto" />;

  if (notFound) return <Card>Produto não encontrado</Card>;

  async function handleProdutoUpdate(produto: Produto.ProdutoRequest) {
    await ProdutoService.update(Number(params.id), produto).then(() => {
      navigate("/produto");
      notification.success({
        message: "Produto atualizado com sucesso",
      });
    });
  }

  if (!produto) return <Skeleton />;

  return <ProdutoForm produto={produto} onUpdate={handleProdutoUpdate} />;
}
