import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { OrdemLocacao } from "../../app/sdk/@types";
import OrdemLocacaoService from "../../app/sdk/services/OrdemLocacao.service";

interface LocacaoState {
  fetching: boolean;
  list: OrdemLocacao.ItemLocacaoResponse[];
}

const initialState: LocacaoState = {
  fetching: false,
  list: [],
};

export const buscarTodasOrdensLocacao = createAsyncThunk(
  "locacao/buscarTodasLocacoes",
  async () => OrdemLocacaoService.findAll()
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(buscarTodasOrdensLocacao.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addMatcher(isPending(buscarTodasOrdensLocacao), (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled(buscarTodasOrdensLocacao), (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected(buscarTodasOrdensLocacao), (state) => {
      state.fetching = false;
    });
});
