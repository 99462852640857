import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Tooltip } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Produto } from "../sdk/@types";
import useProdutos from "../../core/hooks/useProdutos";
import formatValorMonetario from "../sdk/utils/formatValor";

export default function ProdutoLista() {
  const { produtos, fetching, fetchProdutos } = useProdutos();

  useEffect(() => {
    fetchProdutos();
  }, [fetchProdutos]);

  return (
    <>
      <Table<Produto.ProdutoResponse>
        dataSource={produtos.list}
        rowKey={"id"}
        loading={fetching}
        pagination={{
          pageSize: 10,
        }}
        scroll={{ x: 600 }}
        size="small"
        bordered
        columns={[
          {
            dataIndex: "id",
            title: "ID",
            align: "center",
            width: 60,
          },
          {
            dataIndex: "nome",
            title: "Nome",
            fixed: "left",
            width: 180,
          },
          {
            dataIndex: "unidade",
            title: "Unidade de Locação",
            align: "center",
            width: 180,
            render: (item) => item.toLocaleLowerCase(),
          },
          {
            dataIndex: "quantidadePorUnidade",
            title: "Quantidade por Unidade",
            align: "center",
            width: 230,
            render: (quantidadePorUnidade, record) => {
              const unidade = record.unidade?.toLocaleLowerCase() || "??";
              return `1 ${unidade} equivale a ${
                quantidadePorUnidade || "??"
              } peça(s)`;
            },
          },
          {
            dataIndex: "valorDoBem",
            title: "Valor do Bem",
            align: "center",
            width: 150,
            render: formatValorMonetario,
          },
          {
            dataIndex: "id",
            title: "Ações",
            align: "center",
            width: 100,
            render(id: number) {
              return (
                <Space>
                  <Tooltip title="Visualizar produto" placement="left">
                    <Link to={`/produto/${id}`}>
                      <Button size="small" icon={<EyeOutlined />} />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Editar produto" placement="right">
                    <Link to={`/produto/edicao/${id}`}>
                      <Button size="small" icon={<EditOutlined />} />
                    </Link>
                  </Tooltip>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
}
