import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import * as UsuarioActions from "../store/Usuario.reducer";
import { Usuario } from "../../app/sdk/@types";

export default function useUsuarios() {
  const dispatch = useDispatch<any>();
  const fetching = useSelector((state: RootState) => state.usuario.fetching);
  const usuarios = useSelector((state: RootState) => state.usuario);

  const paginacao = (page: number): Usuario.Query => {
    return {
      page,
      size: 15,
      showAll: true,
      sort: ["nome", "asc"],
    };
  };

  const fetchUsuarios = useCallback(
    async (page: number) => {
      await dispatch(UsuarioActions.getAllUsuarios(paginacao(page)));
    },
    [dispatch]
  );

  const alternarStatusDoUsuario = useCallback(
    async (usuario: Usuario.UsuarioResponse, page: number) => {
      await dispatch(UsuarioActions.alternarStatusDoUsuario(usuario));
      await dispatch(UsuarioActions.getAllUsuarios(paginacao(page)));
    },
    [dispatch]
  );

  return {
    fetchUsuarios,
    usuarios,
    fetching,
    alternarStatusDoUsuario,
  };
}
