import axios from "axios";
import { message, notification } from "antd";
import { Erros, ErrorResponse } from "../@types/ErrosApi";

export default function notificationError(reason: any) {
  if (axios.isAxiosError(reason)) {
    const data = reason.response?.data as Erros | ErrorResponse;
    if ("erros" in data && Array.isArray(data.erros)) {
      data.erros.forEach((error) => {
        message.error(error);
      });
    } else {
      const errorResponse = data as ErrorResponse;
      notification.error({
        message: `Erro ${errorResponse.status}`,
        description: errorResponse.message || "Ocorreu um erro desconhecido.",
      });
    }
  } else {
    notification.error({
      message: "Ocorreu um erro",
      description: reason?.message || "Ocorreu um erro desconhecido.",
    });
  }
}
