import { useCallback, useState } from "react";
import { Usuario } from "../../app/sdk/@types";
import UsuarioService from "../../app/sdk/services/Usuario.service";
import { AxiosError } from "axios";

export default function useUsuario() {
  const [usuario, setUsuario] = useState<Usuario.UsuarioResponse>();
  const [notFound, setNotFound] = useState(false);

  const fetchUsuario = useCallback(async (idUsuario: number) => {
    try {
      await UsuarioService.findById(idUsuario).then(setUsuario);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        setNotFound(true);
      } else {
        throw error;
      }
    }
  }, []);

  const alternarStatusDoUsuario = useCallback(
    async (usuario: Usuario.UsuarioResponse) => {
      return usuario.ativo
        ? UsuarioService.desativar(Number(usuario.id))
        : UsuarioService.ativar(Number(usuario.id));
    },
    []
  );

  return {
    usuario,
    fetchUsuario,
    notFound,
    alternarStatusDoUsuario,
  };
}
