import Service from "../Service";
import generateQueryString from "../utils/generateQueryString";
import { Usuario } from "../@types";

class UsuarioService extends Service {
  static findById(idUsuario: number) {
    return this.Http.get<Usuario.UsuarioResponse>(`/usuario/${idUsuario}`).then(
      this.getData
    );
  }

  static getAll(search?: Usuario.Query) {
    if (!search) {
      search = { page: 0, size: 10, showAll: true, sort: ["nome", "asc"] };
    }
    const queryString = generateQueryString(search);
    return this.Http.get<Usuario.PageUsuarioResponse>(
      "/usuario".concat(queryString)
    ).then(this.getData);
  }

  static insert(usuario: Usuario.UsuarioRequest) {
    return this.Http.post<Usuario.UsuarioResponse>("/usuario", usuario).then(
      this.getData
    );
  }

  static update(idUsuario: number, usuario: Usuario.UsuarioRequest) {
    return this.Http.put<Usuario.UsuarioResponse>(
      `/usuario/${idUsuario}`,
      usuario
    ).then(this.getData);
  }

  static ativar(idUsuario: number) {
    return this.Http.patch(`/usuario/${idUsuario}/status/true`).then(
      this.getData
    );
  }

  static desativar(idUsuario: number) {
    return this.Http.patch(`/usuario/${idUsuario}/status/false`).then(
      this.getData
    );
  }
}

export default UsuarioService;
