import { useEffect } from "react";
import useUsuario from "../../core/hooks/useUsuario";
import {
  Avatar,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { Navigate, useParams } from "react-router-dom";
import { UserOutlined, WarningFilled } from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Link } from "react-router-dom";
import confirm from "antd/es/modal/confirm";
import NotFoundError from "../components/NotFoundError";
import usePageTitle from "../../core/hooks/usePageTitle";
import formatTelefone from "../sdk/utils/formatTelefone";
import formatCpfCnpj from "../sdk/utils/formatCpfCnpj";
import { formatData, formatDataHora } from "../sdk/utils/data";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function UsuarioDetalhesView() {
  usePageTitle("Detalhes do usuário");
  const params = useParams<{ id: string }>();
  const { usuario, fetchUsuario, notFound, alternarStatusDoUsuario } =
    useUsuario();
  useBreadcrumb(`Usuário/Detalhe/${usuario?.nome || usuario?.id}`);

  const { lg } = useBreakpoint();

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchUsuario(Number(params.id));
  }, [fetchUsuario, params.id]);

  if (isNaN(Number(params.id))) return <Navigate to="/usuario" />;

  if (notFound)
    return (
      <Card>
        <NotFoundError
          title={"Usuário não encontrado"}
          actionTitle={"Voltar para lista de usuários"}
          actionDestination={"/usuario"}
        />
      </Card>
    );

  if (!usuario) return <Skeleton />;

  return (
    <Row gutter={24}>
      <Col xs={24} lg={4}>
        <Row justify="center">
          <Avatar src={""} size={128} icon={<UserOutlined />} />
        </Row>
      </Col>
      <Col xs={24} lg={20}>
        <Space
          style={{ width: "100%" }}
          direction="vertical"
          align={lg ? "start" : "center"}
        >
          <Typography.Title level={2}>{usuario.nome}</Typography.Title>
          <Space>
            <Link to={`/usuario/edicao/${usuario.id}`}>
              <Button type="primary">Editar Perfil</Button>
            </Link>
            <Popconfirm
              title={
                usuario.ativo
                  ? `Desabilitar ${usuario.nome}`
                  : `Habilitar ${usuario.nome}`
              }
              onConfirm={() => {
                confirm({
                  icon: <WarningFilled style={{ color: "#09f" }} />,
                  title: `Tem certeza que deseja ${
                    usuario.ativo ? "desabilitar" : "habilitar"
                  } o usuário de ${usuario.nome} ?`,
                  onOk() {
                    alternarStatusDoUsuario(usuario).then(() => {
                      fetchUsuario(Number(params.id));
                    });
                  },
                });
              }}
            >
              <Button type="primary">
                {usuario.ativo ? "Desabilitar" : "Habilitar"}
              </Button>
            </Popconfirm>
          </Space>
        </Space>
      </Col>
      <Divider />
      <Col xs={24}>
        <Descriptions column={1} bordered size={"small"}>
          <Descriptions.Item label={"Data de Cadastro"}>
            {formatDataHora(usuario.dataCadastro)}
          </Descriptions.Item>
          <Descriptions.Item label={"Data de Nascimento"}>
            {formatData(usuario.dataNascimento)}
          </Descriptions.Item>
          <Descriptions.Item label={"CPF"}>
            {formatCpfCnpj(usuario.cpfCnpj)}
          </Descriptions.Item>
          <Descriptions.Item label={"E-mail"}>
            {usuario.email}
          </Descriptions.Item>
          <Descriptions.Item label={"Login"}>{usuario.login}</Descriptions.Item>
          <Descriptions.Item label={"Telefone"}>
            {formatTelefone(usuario.telefone)}
          </Descriptions.Item>
          <Descriptions.Item label={"Celular"}>
            {formatTelefone(usuario.celular)}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}
