export default function formatTelefone(numeroDoTelefone?: string) {
  if (!numeroDoTelefone) return numeroDoTelefone;

  const cleanedNumber = numeroDoTelefone.replace(/\D/g, "");

  if (cleanedNumber.length === 10) {
    return cleanedNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else if (cleanedNumber.length === 11) {
    return cleanedNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
  return cleanedNumber;
}
