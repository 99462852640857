import { PictureOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Row, Avatar, Typography } from "antd";

const { Title } = Typography;
const { Header } = Layout;

export default function DefaultLayoutHeader() {
  return (
    <Header className="header no-print">
      <Row style={{ height: "100%" }} justify="space-between" align="middle">
        <Row style={{ height: "100%" }} align="middle">
          <PictureOutlined style={{ color: "silver", fontSize: "32px" }} />
          <Title
            style={{ color: "silver", margin: 0, marginLeft: 10 }}
            level={2}
          >
            Logo
          </Title>
        </Row>
        <Avatar
          icon={<UserOutlined />}
          style={{ backgroundColor: "silver", verticalAlign: "middle" }}
        />
      </Row>
    </Header>
  );
}
