import {
  createAsyncThunk,
  createReducer,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import ClienteService from "../../app/sdk/services/Cliente.service";
import ProdutoService from "../../app/sdk/services/Produto.service";
import { Cliente, Produto } from "../../app/sdk/@types";

interface ComboboxPayload {
  type: "clientes" | "produtos";
  data: Cliente.ComboBox[] | Produto.ComboBox[];
}

interface ComboboxState {
  fetching: boolean;
  data: {
    clientes: Cliente.ComboBox[];
    produtos: Produto.ComboBox[];
  };
  error?: string;
}

const initialState: ComboboxState = {
  fetching: false,
  data: {
    clientes: [],
    produtos: [],
  },
  error: undefined,
};

export const fetchCombobox = createAsyncThunk<
  ComboboxPayload,
  "clientes" | "produtos"
>("combobox/fetchCombobox", async (type) => {
  if (type === "clientes") {
    const data = await ClienteService.getComboBox();
    return { type, data };
  } else if (type === "produtos") {
    const data = await ProdutoService.getComboBox();
    return { type, data };
  }
  throw new Error("Tipo inválido para combobox");
});

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchCombobox.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.data[action.payload.type] = action.payload.data as any;
      }
      state.fetching = false;
    })
    .addMatcher(isPending(fetchCombobox), (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled(fetchCombobox), (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected(fetchCombobox), (state, action) => {
      state.error = action.error.message || "Erro desconhecido";
      state.fetching = false;
    });
});
