import { Col, Row, Typography } from "antd";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

const { Title, Paragraph } = Typography;

function InicioView() {
  useBreadcrumb("Início");

  return (
    <div>
      <Row>
        <Col span={24}>
          <Title level={2}>Olá, Usuário</Title>
          <Paragraph>
            Este é o resumo da empresa nos últimos doze meses
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
}

export default InicioView;
