import React from "react";
import { Layout } from "antd";
import Breadcrumb from "./Breadcrumb";
import DefaultLayoutSidebar from "./Sidebar";
import DefaultLayoutHeader from "./Header";
import DefaultLayoutContent from "./Content";
import { Footer } from "antd/es/layout/layout";

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <Layout style={{ minHeight: "100vh", overflow: "hidden" }}>
      <DefaultLayoutHeader />
      <Layout id="PageLayout">
        <DefaultLayoutSidebar />
        <Layout style={{ padding: "0 15px" }}>
          <Breadcrumb />
          <DefaultLayoutContent>{props.children}</DefaultLayoutContent>
          <Footer className="no-print" style={{ textAlign: "center" }}>
            {new Date().getFullYear()} © Desenvolvido por Alex Yoshitake
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}
