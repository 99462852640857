export interface FrequenciaLocacaoProps {
    descricao: string;
    quantidade: number | undefined
}

export function FrequenciaLocacao(periodicidade?: string): FrequenciaLocacaoProps | undefined {
    switch (periodicidade) {
        case 'DIA':
            return { descricao: 'Dia', quantidade: undefined };
        case 'SEMANA':
            return { descricao: 'Semana', quantidade: 5 };
        case 'QUINZENA':
            return { descricao: 'Quinzena', quantidade: 15 };
        case 'MES':
            return { descricao: 'Mês', quantidade: 30 };
    }
};
