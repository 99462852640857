import dayjs from "dayjs";

export function formatDataHora(data?: Date | number | string) {
  if (!data) return data;
  return dayjs(data).format("DD/MM/YYYY HH:mm:ss");
}

export function formatData(data?: Date | number | string) {
  if (!data) return data;
  return dayjs(data).format("DD/MM/YYYY");
}
