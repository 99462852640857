import { useCallback, useState } from "react";
import { Cliente } from "../../app/sdk/@types";
import { AxiosError } from "axios";
import ClienteService from "../../app/sdk/services/Cliente.service";

export default function useCliente() {
  const [cliente, setCliente] = useState<Cliente.ClienteResponse>();
  const [notFound, setNotFound] = useState(false);

  const fetchCliente = useCallback(async (idCliente: number) => {
    try {
      await ClienteService.findById(idCliente).then(setCliente);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        setNotFound(true);
      } else {
        throw error;
      }
    }
  }, []);

  const alternarStatusDoCliente = useCallback(
    async (cliente: Cliente.ClienteResponse) => {
      return cliente.ativo
        ? ClienteService.desativar(Number(cliente.id))
        : ClienteService.ativar(Number(cliente.id));
    },
    []
  );

  return {
    cliente,
    notFound,
    fetchCliente,
    alternarStatusDoCliente,
  };
}
