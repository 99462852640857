import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchCombobox } from "../store/Combobox.reducer";

type ComboboxType = "clientes" | "produtos";

export default function useComboBox(type: ComboboxType) {
  const dispatch = useDispatch<AppDispatch>();
  const comboboxData = useSelector(
    (state: RootState) => state.combobox.data[type]
  );
  const fetchingCombos = useSelector(
    (state: RootState) => state.combobox.fetching
  );
  const errorCombos = useSelector((state: RootState) => state.combobox.error);

  const fetchComboboxData = useCallback(() => {
    dispatch(fetchCombobox(type));
  }, [dispatch, type]);

  return {
    comboboxData,
    fetchingCombos,
    errorCombos,
    fetchComboboxData,
  };
}
