import React, { useState, useEffect, useCallback } from "react";
import { Input } from "antd";

type InputMoneyProps = React.ComponentProps<typeof Input> & {
  value?: string | number;
  onChange?: (value: number | null) => void;
  includeCurrencyPrefix?: boolean;
};

const InputMoney: React.FC<InputMoneyProps> = ({
  value,
  onChange,
  includeCurrencyPrefix = true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  const formatValue = useCallback(
    (value: string | number | undefined) => {
      if (value != null) {
        const numericValue =
          typeof value === "number"
            ? value
            : parseFloat(value.replace(/\D/g, "")) / 100;
        const options = includeCurrencyPrefix
          ? {
              style: "currency",
              currency: "BRL",
            }
          : {};
        const formattedValue = new Intl.NumberFormat("pt-BR", {
          ...options,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(numericValue);
        setInputValue(formattedValue);
      } else {
        setInputValue("");
      }
    },
    [includeCurrencyPrefix]
  );

  useEffect(() => {
    formatValue(value);
  }, [value, includeCurrencyPrefix, formatValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, "");
    const numericValue = parseFloat(rawValue) / 100;
    onChange?.(isNaN(numericValue) ? null : numericValue);
  };

  return (
    <Input
      {...props}
      value={inputValue}
      onChange={handleInputChange}
      placeholder="R$ 0,00"
    />
  );
};

export default InputMoney;
