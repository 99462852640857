import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaskedInput from "antd-mask-input";
import { Dayjs } from "dayjs";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Upload,
  notification,
} from "antd";
import { AxiosError } from "axios";
import { Erros, ErrorResponse } from "../sdk/@types/ErrosApi";
import UsuarioService from "../sdk/services/Usuario.service";
import { Usuario } from "../sdk/@types";
import { UserOutlined } from "@ant-design/icons";
import ImageCrop from "antd-img-crop";

type UsuarioFormType = {
  dataNascimento: Dayjs;
  dataCadastro: Dayjs;
} & Omit<Usuario.UsuarioResponse, "dataNascimento" | "dataCadastro">;

interface UsuarioFormProps {
  usuario?: UsuarioFormType;
  onUpdate?: (user: Usuario.UsuarioRequest) => Promise<any>;
}

export default function UsuarioForm(props: UsuarioFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<Usuario.UsuarioRequest>();
  const [avatar, setAvatar] = useState(
    // props.usuario.avatarUrls.default ||
    ""
  );

  const handleAvatarUpload = useCallback(async (file: File) => {
    // TODO: FileService.upload.....
    setAvatar(file.name);
  }, []);

  const cellphoneMask = "(00) 0 0000-0000";
  const phoneMask = "(00) 0000-0000";

  const maskTelefone = React.useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: true,
      },
      {
        mask: phoneMask,
        lazy: true,
      },
    ],
    []
  );

  const maskCPF = React.useMemo(
    () => [
      {
        mask: "000.000.000-00",
        lazy: true,
      },
    ],
    []
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (usuario) => {
        setLoading(true);
        const usuarioDTO: Usuario.UsuarioRequest = {
          ...usuario,
          cpfCnpj: usuario.cpfCnpj?.replace(/\D/g, ""),
          telefone: usuario.telefone?.replace(/\D/g, ""),
          celular: usuario.celular?.replace(/\D/g, ""),
        };

        if (props.usuario)
          return (
            props.onUpdate &&
            props.onUpdate(usuarioDTO).finally(() => {
              setLoading(false);
            })
          );

        try {
          await UsuarioService.insert(usuarioDTO);
          navigate("/usuario");
          notification.success({
            message: "Sucesso",
            description: "Usuário criado com sucesso",
          });
        } catch (reason) {
          if (reason instanceof AxiosError) {
            const data = reason.response?.data as Erros | ErrorResponse;
            console.error(data);
            // form.setFields(data.)
          } else {
            notification.error({
              message: "Ocorreu um erro",
            });
          }
        } finally {
          setLoading(false);
        }
      }}
      initialValues={props.usuario}
    >
      <Row gutter={24} align="middle">
        <Col xs={24} lg={4} style={{ textAlign: "center" }}>
          <ImageCrop cropShape="round" showGrid rotationSlider>
            <Upload
              maxCount={1}
              onRemove={() => setAvatar("")}
              beforeUpload={(file) => {
                handleAvatarUpload(file);
                return false;
              }}
              fileList={[
                ...(avatar
                  ? [
                      {
                        name: "Avatar",
                        uid: "",
                      },
                    ]
                  : []),
              ]}
            >
              <Avatar
                src={avatar}
                size={128}
                icon={<UserOutlined />}
                style={{ cursor: "pointer" }}
              />
            </Upload>
          </ImageCrop>
        </Col>
        <Col xs={24} lg={20}>
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: João Silva" />
          </Form.Item>
          <Form.Item label="Data de Nascimento" name="dataNascimento">
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Divider />
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Perfil de Acesso"
            name="rule"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Select placeholder="Selecione um perfil de acesso">
              <Select.Option value={"ADMINISTRADOR"}>
                Administrador
              </Select.Option>
              <Select.Option value={"FUNCIONARIO"}>Funcionário</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input type="email" placeholder="Ex.: contato@joao.silva" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Login"
            name="login"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: joao.silva" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Senha"
            name="senha"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input type="password" placeholder="Ex.: **********" />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Divider />
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item
                label="CPF"
                name="cpfCnpj"
                rules={[{ required: true, message: "O campo é obrigatório" }]}
              >
                <MaskedInput mask={maskCPF} placeholder="Ex.: 111.222.333-44" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label="Telefone" name="telefone">
                <MaskedInput
                  mask={maskTelefone}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const isCellPhone =
                        dynamicMasked.unmaskedValue[2] === "9";
                      return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    },
                  }}
                  placeholder="Ex.: (18) 99999-0000"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label="Celular" name="celular">
                <MaskedInput
                  mask={maskTelefone}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const isCellPhone =
                        dynamicMasked.unmaskedValue[2] === "9";
                      return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    },
                  }}
                  placeholder="Ex.: (18) 99999-0000"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col xs={24}>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {props.usuario ? "Atualizar usuário" : "Cadastrar usuário"}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
