import { Table, Tooltip, Typography } from "antd";
import { OrdemLocacao } from "../sdk/@types";
import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import formatValorMonetario from "../sdk/utils/formatValor";

interface ItemLocacaoReadOnlyProps {
  itensLocacao?: OrdemLocacao.ItemLocacaoResponse[];
  frequenciaLocacao?: "DIA" | "SEMANA" | "QUINZENA" | "MES";
  quantidadeDias?: number;
}

export default function ItemLocacaoReadOnly(props: ItemLocacaoReadOnlyProps) {
  const calculoValorTotal = (item: OrdemLocacao.ItemLocacaoResponse) => {
    const valor = Number(item.valorUnitario) * Number(item.quantidade);
    return props.frequenciaLocacao === "DIA"
      ? valor * Number(props.quantidadeDias)
      : valor;
  };

  return (
    <Table<OrdemLocacao.ItemLocacaoResponse>
      size="small"
      dataSource={props.itensLocacao}
      pagination={false}
      rowKey="id"
      bordered
      scroll={{ x: 600 }}
      columns={[
        {
          title: "#",
          dataIndex: "index",
          key: "index",
          rowScope: "row",
          align: "center",
          width: "5%",
          render: (text, record, index) => index + 1,
        },
        {
          title: "Produto",
          dataIndex: ["produto", "nome"],
          key: "produto",
          render: (_, record) => (
            <>
              {record.produto?.nome}
              {!record.produto?.valor && (
                <Tooltip title="Não há valores no cadastro do produto!">
                  <InfoCircleOutlined style={{ color: "red", marginLeft: 8 }} />
                </Tooltip>
              )}
            </>
          ),
        },
        {
          title: "Quantidade",
          dataIndex: "quantidade",
          key: "quantidade",
          align: "center",
          render: (quantidade, record) => (
            <>
              {quantidade} {record.produto?.unidade?.toLowerCase()}(s){" "}
              <ArrowRightOutlined />{" "}
              {quantidade * Number(record.produto?.quantidadePorUnidade)}{" "}
              peça(s)
            </>
          ),
        },
        {
          title: "Valor Unitário",
          dataIndex: "valorUnitario",
          key: "valorUnitario",
          align: "center",
          render: formatValorMonetario,
        },
        {
          title: `Valor Total (${props.quantidadeDias} dias)`,
          dataIndex: "valorTotal",
          key: "valorTotal",
          align: "center",
          render: (_, record) => {
            const valorTotal = calculoValorTotal(record);
            return formatValorMonetario(valorTotal);
          },
        },
      ]}
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={1} colSpan={4} align="right">
            <Typography.Text strong>TOTAL</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="center">
            {formatValorMonetario(
              props.itensLocacao?.reduce(
                (total, record) => total + calculoValorTotal(record),
                0
              )
            )}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    />
  );
}
