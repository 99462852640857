import React, { useEffect } from "react";
import { Select } from "antd";
import useComboBox from "../../core/hooks/useComboBox";
import { SelectProps } from "antd/lib";

interface ClienteComboBoxComponentProps extends SelectProps<any> {}

export default function ClienteComboBoxComponent(
  props: ClienteComboBoxComponentProps
) {
  const { comboboxData, fetchingCombos, errorCombos, fetchComboboxData } =
    useComboBox("clientes");

  useEffect(() => {
    fetchComboboxData();
  }, [fetchComboboxData]);

  if (errorCombos) return <p>Erro: {errorCombos}</p>;

  return (
    <Select
      {...props}
      placeholder={props.placeholder || "Selecione um cliente"}
      loading={fetchingCombos}
    >
      {comboboxData.map((combo) => (
        <Select.Option key={combo.value} value={combo.value}>
          {`${combo.value} - ${combo.description}`}
        </Select.Option>
      ))}
    </Select>
  );
}
