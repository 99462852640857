import { useEffect } from "react";
import { Route, Routes as BrowserRoutes } from "react-router-dom";
import InicioView from "./views/Inicio.view";
import LocacaoListaView from "./views/LocacaoLista.view";
import LocacaoDetalhesView from "./views/LocacaoDetalhes.view";
import LocacaoCadastroView from "./views/LocacaoCadastro.view";
import ClienteListaView from "./views/ClienteLista.view";
import ClienteCadastroView from "./views/ClienteCadastro.view";
import ClienteDetalhesView from "./views/ClienteDetalhes.view";
import ClienteEdicaoView from "./views/ClienteEdicao.view";
import ProdutoListaView from "./views/ProdutoLista.view";
import ProdutoCadastroView from "./views/ProdutoCadastro.view";
import ProdutoEdicaoView from "./views/ProdutoEdicao.view";
import UsuarioListaView from "./views/UsuarioLista.view";
import UsuarioCadastroView from "./views/UsuarioCadastro.view";
import UsuarioDetalhesView from "./views/UsuarioDetalhes.view";
import UsuarioEdicaoView from "./views/UsuarioEdicao.view";
import notificationError from "./sdk/utils/notificationError";

export default function Routes() {
  useEffect(() => {
    window.onunhandledrejection = ({ reason }) => notificationError(reason);

    return () => {
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <BrowserRoutes>
      <Route path="/" Component={InicioView} />
      <Route path="/locacao" Component={LocacaoListaView} />
      <Route path="/locacao/:id" Component={LocacaoDetalhesView} />
      <Route path="/locacao/cadastro" Component={LocacaoCadastroView} />
      <Route path="/cliente" Component={ClienteListaView} />
      <Route path="/cliente/cadastro" Component={ClienteCadastroView} />
      <Route path="/cliente/:id" Component={ClienteDetalhesView} />
      <Route path="/cliente/edicao/:id" Component={ClienteEdicaoView} />
      <Route path="/produto" Component={ProdutoListaView} />
      <Route path="/produto/cadastro" Component={ProdutoCadastroView} />
      <Route path="/produto/edicao/:id" Component={ProdutoEdicaoView} />
      <Route path="/usuario" Component={UsuarioListaView} />
      <Route path="/usuario/cadastro" Component={UsuarioCadastroView} />
      <Route path="/usuario/:id" Component={UsuarioDetalhesView} />
      <Route path="/usuario/edicao/:id" Component={UsuarioEdicaoView} />
    </BrowserRoutes>
  );
}
