import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import { AxiosError } from "axios";
import { Erros, ErrorResponse } from "../sdk/@types/ErrosApi";
import ProdutoService from "../sdk/services/Produto.service";
import { Produto } from "../sdk/@types";
import TextArea from "antd/es/input/TextArea";
import InputMoney from "../components/CurrencyInput";

interface ProdutoFormProps {
  produto?: Produto.ProdutoResponse;
  onUpdate?: (produto: Produto.ProdutoRequest) => Promise<any>;
}

export default function ProdutoForm(props: ProdutoFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<Produto.ProdutoRequest>();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (produto) => {
        setLoading(true);

        console.log(produto);
        if (props.produto)
          return (
            props.onUpdate &&
            props.onUpdate(produto).finally(() => {
              setLoading(false);
            })
          );

        try {
          console.log(produto);
          await ProdutoService.insert(produto);
          navigate("/produto");
          notification.success({
            message: "Sucesso",
            description: "Produto cadastrado com sucesso",
          });
        } catch (reason) {
          if (reason instanceof AxiosError) {
            const data = reason.response?.data as Erros | ErrorResponse;
            console.error(data);
            // form.setFields(data.)
          } else {
            notification.error({
              message: "Ocorreu um erro",
            });
          }
        } finally {
          setLoading(false);
        }
      }}
      initialValues={props.produto}
    >
      <Row gutter={24} align="middle">
        <Col xs={24} style={{ marginBottom: 18 }}>
          <Typography.Title level={4}>Produto</Typography.Title>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Nome"
            name="nome"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: Ferramenta ABC" />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label="Valor do Bem"
            name="valorDoBem"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <InputMoney
              addonBefore="R$"
              includeCurrencyPrefix={false}
              autoComplete="off"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label="Unidade"
            name="unidade"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Selecione..."
              options={[
                { value: "METRO", label: "Metro" },
                { value: "UNIDADE", label: "Unidade" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label="Quantidade por Unidade"
            name="quantidadePorUnidade"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <InputNumber
              min={1}
              style={{ width: "100%" }}
              placeholder="Ex.: 123"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Descrição" name="descricao">
            <TextArea
              rows={4}
              placeholder="Ex.: Ferramenta de X potência ... "
            />
          </Form.Item>
        </Col>

        <Divider />

        <Col xs={24} style={{ marginBottom: 18 }}>
          <Typography.Title level={4}>Valor</Typography.Title>
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={5}>
              <Form.Item
                label="Valor do Dia Corrente"
                name={["valor", "valorDiaCorrente"]}
                rules={[{ required: true, message: "O campo é obrigatório" }]}
              >
                <InputMoney
                  addonBefore="R$"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={5}>
              <Form.Item
                label="Valor por Dia"
                name={["valor", "valorDia"]}
                rules={[{ required: true, message: "O campo é obrigatório" }]}
              >
                <InputMoney
                  addonBefore="R$"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={5}>
              <Form.Item
                name={["valor", "valorSemana"]}
                label="Valor da Semana"
                rules={[
                  {
                    required: true,
                    message: "O campo Valor por Semana é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item
                name={["valor", "valorSemanaDiaAdicional"]}
                label="Valor da Semana (Dia Adicional)"
                rules={[
                  {
                    required: true,
                    message:
                      "O campo Valor por Semana (dia adicional) é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  addonAfter="por dia adicional"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={5}>
              <Form.Item
                name={["valor", "valorQuinzena"]}
                label="Valor da Quinzena"
                rules={[
                  {
                    required: true,
                    message: "O campo Valor por Quinzena é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item
                name={["valor", "valorQuinzenaDiaAdicional"]}
                label="Valor da Quinzena (Dia Adicional)"
                rules={[
                  {
                    required: true,
                    message:
                      "O campo Valor por Quinzena (dia adicional) é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  addonAfter="por dia adicional"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={5}>
              <Form.Item
                name={["valor", "valorMes"]}
                label="Valor do Mês"
                rules={[
                  {
                    required: true,
                    message: "O campo Valor por Mês é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={7}>
              <Form.Item
                name={["valor", "valorMesDiaAdicional"]}
                label="Valor do Mês (Dia Adicional)"
                rules={[
                  {
                    required: true,
                    message:
                      "O campo Valor por Mês (dia adicional) é obrigatório",
                  },
                ]}
              >
                <InputMoney
                  addonBefore="R$"
                  addonAfter="por dia adicional"
                  includeCurrencyPrefix={false}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Divider />
        <Col xs={24}>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {props.produto ? "Atualizar produto" : "Cadastrar produto"}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
