import { OrdemLocacao } from "../../sdk/@types";
import Service from "../Service";

class OrdemLocacaoService extends Service {
  static findAll() {
    return this.Http.get<OrdemLocacao.OrdemLocacaoResponse[]>(
      "/ordem-locacao"
    ).then(this.getData);
  }

  static getById(idOrdemLocacao: number) {
    return this.Http.get<OrdemLocacao.OrdemLocacaoResponse>(
      `/ordem-locacao/${idOrdemLocacao}`
    ).then(this.getData);
  }

  static insert(locacao: OrdemLocacao.OrdemLocacaoRequest) {
    return this.Http.post<OrdemLocacao.OrdemLocacaoResponse>(
      "/ordem-locacao",
      locacao
    ).then(this.getData);
  }
}

export default OrdemLocacaoService;
