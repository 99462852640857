import { Navigate, useParams } from "react-router-dom";
import usePageTitle from "../../core/hooks/usePageTitle";
import useCliente from "../../core/hooks/useCliente";
import { useEffect } from "react";
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Skeleton,
  Typography,
} from "antd";
import NotFoundError from "../components/NotFoundError";
import formatCpfCnpj from "../sdk/utils/formatCpfCnpj";
import formatTelefone from "../sdk/utils/formatTelefone";
import { formatData } from "../sdk/utils/data";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function ClienteDetalhesView() {
  usePageTitle("Detalhes do cliente");
  const params = useParams<{ id: string }>();
  const { cliente, notFound, fetchCliente } = useCliente();
  useBreadcrumb(`Cliente/Detalhe/${cliente?.nome || cliente?.id}`);

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchCliente(Number(params.id));
  }, [fetchCliente, params.id]);

  if (isNaN(Number(params.id))) return <Navigate to="/cliente" />;

  if (notFound)
    return (
      <Card>
        <NotFoundError
          title={"Cliente não encontrado"}
          actionTitle={"Voltar para lista de clientes"}
          actionDestination={"/cliente"}
        />
      </Card>
    );

  if (!cliente) return <Skeleton />;

  return (
    <Form>
      <Row gutter={24} align="middle">
        <Col xs={24} style={{ marginBottom: 18 }}>
          <Typography.Title level={4}>Dados Pessoais</Typography.Title>
        </Col>
        <Col xs={24} lg={18}>
          <Form.Item label="Nome / Razão Social">
            <Input value={cliente.nome} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label="Data de Nascimento">
            <Input value={formatData(cliente.dataNascimento)} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item label="CPF / CNPJ">
                <Input value={formatCpfCnpj(cliente.cpfCnpj)} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="RG / IE">
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider />
              <Typography.Title level={4} style={{ marginBottom: 18 }}>
                Contato
              </Typography.Title>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="E-mail">
                <Input value={cliente.email} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item label="Telefone">
                <Input value={formatTelefone(cliente.telefone)} readOnly />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item label="Celular">
                <Input value={formatTelefone(cliente.celular)} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Divider />
          <Typography.Title level={4} style={{ marginBottom: 18 }}>
            Endereço
          </Typography.Title>
        </Col>
        <Col xs={24} lg={14}>
          <Form.Item label="Logradouro">
            <Input value={cliente.endereco?.logradouro} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item label="Número">
            <Input value={cliente.endereco?.numero} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label="Complemento">
            <Input value={cliente.endereco?.complemento} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label="Bairro">
            <Input value={cliente.endereco?.bairro} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={10}>
          <Form.Item label="Cidade">
            <Input value={cliente.endereco?.cidade} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item label="Estado">
            <Input value={cliente.endereco?.estado} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item label="CEP">
            <Input value={cliente.endereco?.cep} readOnly />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Referência">
            <Input value={cliente.endereco?.referencia} readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
