import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import * as ProdutoActions from "../store/Produto.reducer";

export default function useProdutos() {
  const dispatch = useDispatch<any>();
  const fetching = useSelector((state: RootState) => state.produto.fetching);
  const produtos = useSelector((state: RootState) => state.produto);

  const fetchProdutos = useCallback(async () => {
    await dispatch(ProdutoActions.buscarTodosProdutos());
  }, [dispatch]);

  return {
    produtos,
    fetching,
    fetchProdutos,
  };
}
