import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MaskedInput from "antd-mask-input";
import { Dayjs } from "dayjs";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from "antd";
import { AxiosError } from "axios";
import { Erros, ErrorResponse } from "../sdk/@types/ErrosApi";
import ClienteService from "../sdk/services/Cliente.service";
import { Cliente } from "../sdk/@types";

type ClienteFormType = {
  dataNascimento: Dayjs;
  dataCadastro: Dayjs;
} & Omit<Cliente.ClienteResponse, "dataNascimento" | "dataCadastro">;

interface ClienteFormProps {
  cliente?: ClienteFormType;
  onUpdate?: (user: Cliente.ClienteRequest) => Promise<any>;
}

export default function ClienteForm(props: ClienteFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<Cliente.ClienteRequest>();

  const cellphoneMask = "(00) 0 0000-0000";
  const phoneMask = "(00) 0000-0000";

  const maskTelefone = React.useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: true,
      },
      {
        mask: phoneMask,
        lazy: true,
      },
    ],
    []
  );

  const maskCPF = React.useMemo(
    () => [
      {
        mask: "000.000.000-00",
        lazy: true,
      },
    ],
    []
  );

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (cliente) => {
        setLoading(true);
        const dto: Cliente.ClienteRequest = {
          ...cliente,
          cpfCnpj: cliente.cpfCnpj?.replace(/\D/g, ""),
          telefone: cliente.telefone?.replace(/\D/g, ""),
          celular: cliente.celular?.replace(/\D/g, ""),
        };

        if (props.cliente)
          return (
            props.onUpdate &&
            props.onUpdate(dto).finally(() => {
              setLoading(false);
            })
          );

        try {
          await ClienteService.insert(dto);
          navigate("/cliente");
          notification.success({
            message: "Sucesso",
            description: "Cliente cadastrado com sucesso",
          });
        } catch (reason) {
          if (reason instanceof AxiosError) {
            const data = reason.response?.data as Erros | ErrorResponse;
            console.error(data);
            // form.setFields(data.)
          } else {
            notification.error({
              message: "Ocorreu um erro",
            });
          }
        } finally {
          setLoading(false);
        }
      }}
      initialValues={props.cliente}
    >
      <Row gutter={24} align="middle">
        <Col xs={24} style={{ marginBottom: 18 }}>
          <Typography.Title level={4}>Dados Pessoais</Typography.Title>
        </Col>
        <Col xs={24} lg={18}>
          <Form.Item
            label="Nome / Razão Social"
            name="nome"
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: João Silva" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label="Data de Nascimento" name="dataNascimento">
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Row gutter={24}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="CPF / CNPJ"
                name="cpfCnpj"
                rules={[{ required: true, message: "O campo é obrigatório" }]}
              >
                <MaskedInput mask={maskCPF} placeholder="Ex.: 111.222.333-44" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="RG / IE" name="">
                <MaskedInput mask={maskCPF} placeholder="Ex.: 111.222.333-44" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider />
              <Typography.Title level={4} style={{ marginBottom: 18 }}>
                Contato
              </Typography.Title>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[{ required: true, message: "O campo é obrigatório" }]}
              >
                <Input type="email" placeholder="Ex.: contato@joao.silva" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item label="Telefone" name="telefone">
                <MaskedInput
                  mask={maskTelefone}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const isCellPhone =
                        dynamicMasked.unmaskedValue[2] === "9";
                      return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    },
                  }}
                  placeholder="Ex.: (18) 99999-0000"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
              <Form.Item label="Celular" name="celular">
                <MaskedInput
                  mask={maskTelefone}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const isCellPhone =
                        dynamicMasked.unmaskedValue[2] === "9";
                      return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                    },
                  }}
                  placeholder="Ex.: (18) 99999-0000"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Divider />
          <Typography.Title level={4} style={{ marginBottom: 18 }}>
            Endereço
          </Typography.Title>
        </Col>
        <Col xs={24} lg={14}>
          <Form.Item
            label="Logradouro"
            name={["endereco", "logradouro"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: Rua da Cidade" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item
            label="Número"
            name={["endereco", "numero"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: 123" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item label="Complemento" name={["endereco", "complemento"]}>
            <Input placeholder="Ex.: Apartamento 123" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={6}>
          <Form.Item
            label="Bairro"
            name={["endereco", "bairro"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: Centro" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={10}>
          <Form.Item
            label="Cidade"
            name={["endereco", "cidade"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: Presidente Epitácio" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item
            label="Estado"
            name={["endereco", "estado"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: SP" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={4}>
          <Form.Item
            label="CEP"
            name={["endereco", "cep"]}
            rules={[{ required: true, message: "O campo é obrigatório" }]}
          >
            <Input placeholder="Ex.: 19470-000" />
          </Form.Item>
        </Col>
        <Divider />
        <Col xs={24}>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading}>
              {props.cliente ? "Atualizar cliente" : "Cadastrar cliente"}
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
