import { useEffect, useState } from "react";
import useUsuarios from "../../core/hooks/useUsuarios";
import { Button, Space, Switch, Table, Tooltip } from "antd";
import { Cliente } from "../sdk/@types";
import { formatDataHora } from "../sdk/utils/data";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function UsuarioLista() {
  const { usuarios, fetching, fetchUsuarios, alternarStatusDoUsuario } =
    useUsuarios();
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchUsuarios(page);
  }, [fetchUsuarios, page]);

  return (
    <>
      <Table<Cliente.ClienteResponse>
        dataSource={usuarios.list.content}
        rowKey={"id"}
        loading={fetching}
        pagination={{
          onChange: (page) => setPage(page - 1),
          total: usuarios.list.totalElements,
          pageSize: usuarios.list.size,
        }}
        scroll={{ x: 600 }}
        size="small"
        bordered
        columns={[
          {
            dataIndex: "id",
            title: "ID",
            align: "center",
            width: "5%",
          },
          {
            dataIndex: "nome",
            title: "Nome",
            fixed: "left",
          },
          {
            dataIndex: "login",
            title: "Login",
            align: "center",
          },
          {
            dataIndex: "dataCadastro",
            title: "Data do Cadastro",
            align: "center",
            render: formatDataHora
          },
          {
            dataIndex: "ativo",
            title: "Ativo",
            align: "center",
            render(ativo: boolean, usuario) {
              return (
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={() => {
                    alternarStatusDoUsuario(usuario, page);
                  }}
                  checked={ativo}
                />
              );
            },
          },
          {
            dataIndex: "id",
            title: "Ações",
            align: "center",
            render(id: number) {
              return (
                <Space>
                  <Tooltip title="Visualizar usuário" placement="left">
                    <Link to={`/usuario/${id}`}>
                      <Button size="small" icon={<EyeOutlined />} />
                    </Link>
                  </Tooltip>
                  <Tooltip title="Editar usuário" placement="right">
                    <Link to={`/usuario/edicao/${id}`}>
                      <Button size="small" icon={<EditOutlined />} />
                    </Link>
                  </Tooltip>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
}
