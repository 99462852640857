import { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Card, Skeleton, notification } from "antd";
import dayjs from "dayjs";
import { Cliente } from "../sdk/@types";
import ClienteService from "../sdk/services/Cliente.service";
import ClienteForm from "../features/ClienteForm";
import useCliente from "../../core/hooks/useCliente";
import usePageTitle from "../../core/hooks/usePageTitle";
import notificationError from "../sdk/utils/notificationError";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function UsuarioEdicaoView() {
  usePageTitle("Edição de cliente");
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { cliente, fetchCliente, notFound } = useCliente();
  useBreadcrumb(`Cliente/Edição/${cliente?.nome || cliente?.id}`);

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchCliente(Number(params.id));
  }, [fetchCliente, params.id]);

  const transformUserData = useCallback((cliente: Cliente.ClienteResponse) => {
    return {
      ...cliente,
      dataNascimento: dayjs(cliente.dataNascimento),
      dataCadastro: dayjs(cliente.dataCadastro),
    };
  }, []);

  if (isNaN(Number(params.id))) return <Navigate to="/cliente" />;

  if (notFound) return <Card>Cliente não encontrado</Card>;

  async function handleClienteUpdate(cliente: Cliente.ClienteRequest) {
    try {
      await ClienteService.update(Number(params.id), cliente).then(() => {
        navigate("/cliente");
        notification.success({
          message: "Cliente atualizado com sucesso",
        });
      });
    } catch (reason) {
      notificationError(reason);
    }
  }

  if (!cliente) return <Skeleton />;

  return (
    <ClienteForm
      cliente={transformUserData(cliente)}
      onUpdate={handleClienteUpdate}
    />
  );
}
