import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { Cliente } from "../../app/sdk/@types";
import ClienteService from "../../app/sdk/services/Cliente.service";

interface ClienteState {
  fetching: boolean;
  list: Cliente.PageClienteResponse;
}

const initialState: ClienteState = {
  fetching: false,
  list: {},
};

export const buscarTodosClientes = createAsyncThunk(
  "cliente/buscarTodosClientes",
  async (query: Cliente.Query) => ClienteService.getAll(query)
);

export const alternarStatusDoCliente = createAsyncThunk(
  "cliente/alternarStatusDoCliente",
  async (cliente: Cliente.ClienteResponse) => {
    cliente.ativo
      ? await ClienteService.desativar(Number(cliente.id))
      : await ClienteService.ativar(Number(cliente.id));
    return cliente;
  }
);

export default createReducer(initialState, (builder) => {
  builder
    .addCase(buscarTodosClientes.fulfilled, (state, action) => {
      state.list = action.payload;
    })
    .addMatcher(
      isPending(buscarTodosClientes, alternarStatusDoCliente),
      (state) => {
        state.fetching = true;
      }
    )
    .addMatcher(
      isFulfilled(buscarTodosClientes, alternarStatusDoCliente),
      (state) => {
        state.fetching = false;
      }
    )
    .addMatcher(
      isRejected(buscarTodosClientes, alternarStatusDoCliente),
      (state) => {
        state.fetching = false;
      }
    );
});
