import { useCallback, useState } from "react";
import ProdutoService from "../../app/sdk/services/Produto.service";
import { Produto } from "../../app/sdk/@types";
import { AxiosError } from "axios";

export default function useProduto() {
  const [produto, setProduto] = useState<Produto.ProdutoResponse>();
  const [notFound, setNotFound] = useState(false);

  const fetchProduto = useCallback(async (idProduto: number) => {
    try {
      await ProdutoService.findById(idProduto).then(setProduto);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        setNotFound(true);
      } else {
        throw error;
      }
    }
  }, []);

  return {
    produto,
    fetchProduto,
    notFound,
  };
}
