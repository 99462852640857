import Service from "../Service";
import generateQueryString from "../utils/generateQueryString";
import { Cliente } from "../../sdk/@types";

class ClienteService extends Service {
  static getComboBox() {
    return this.Http.get<Cliente.ComboBox[]>("/cliente/combobox").then(
      this.getData
    );
  }

  static findById(id: number) {
    return this.Http.get<Cliente.ClienteResponse>(`/cliente/${id}`).then(
      this.getData
    );
  }

  static getAll(search?: Cliente.Query) {
    if (!search) {
      search = { page: 0, size: 10, showAll: true, sort: ["nome", "asc"] };
    }
    const queryString = generateQueryString(search);
    return this.Http.get<Cliente.PageClienteResponse>(
      "/cliente".concat(queryString)
    ).then(this.getData);
  }

  static insert(cliente: Cliente.ClienteRequest) {
    return this.Http.post<Cliente.ClienteResponse>("/cliente", cliente).then(
      this.getData
    );
  }

  static update(idCliente: number, cliente: Cliente.ClienteRequest) {
    return this.Http.put<Cliente.ClienteResponse>(
      `/cliente/${idCliente}`,
      cliente
    ).then(this.getData);
  }

  static ativar(idCliente: number) {
    return this.Http.patch(`/cliente/${idCliente}/status/true`).then(
      this.getData
    );
  }

  static desativar(idUsuario: number) {
    return this.Http.patch(`/cliente/${idUsuario}/status/false`).then(
      this.getData
    );
  }
}

export default ClienteService;
