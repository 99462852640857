import { useCallback, useState } from "react";
import { OrdemLocacao } from "../../app/sdk/@types";
import { AxiosError } from "axios";
import OrdemLocacaoService from "../../app/sdk/services/OrdemLocacao.service";

export default function useOrdemLocacao() {
  const [ordemLocacao, setOrdemLocacao] =
    useState<OrdemLocacao.OrdemLocacaoResponse>();
  const [notFound, setNotFound] = useState(false);

  const fetchOrdemLocacao = useCallback(async (idOrdemLocacao: number) => {
    try {
      await OrdemLocacaoService.getById(idOrdemLocacao).then(setOrdemLocacao);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        setNotFound(true);
      } else {
        throw error;
      }
    }
  }, []);

  return {
    ordemLocacao,
    fetchOrdemLocacao,
    notFound,
  };
}
