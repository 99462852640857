import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Card, Col, Divider, Row, Tag, Typography } from "antd";
import NotFoundError from "../components/NotFoundError";
import usePageTitle from "../../core/hooks/usePageTitle";
import useOrdemLocacao from "../../core/hooks/useOrdemLocacao";
import OrdemLocacaoReadOnly from "../features/OrdemLocacaoReadOnly";
import ItemLocacaoReadOnly from "../features/ItemLocacaoReadOnly";
import RegistroMovimentacaoReadOnly from "../features/RegistroMovimentacaoReadOnly";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function LocacaoDetalhesView() {
  usePageTitle("Detalhes da Ordem de Locação");
  const params = useParams<{ id: string }>();
  const { ordemLocacao, fetchOrdemLocacao, notFound } = useOrdemLocacao();
  useBreadcrumb(`Ordem de Locação/Detalhe/${ordemLocacao?.id}`);

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchOrdemLocacao(Number(params.id));
  }, [fetchOrdemLocacao, params.id]);

  if (isNaN(Number(params.id))) return <Navigate to="/locacao" />;

  if (notFound)
    return (
      <Card>
        <NotFoundError
          title={"Locação não encontrada"}
          actionTitle={"Voltar para lista de locações"}
          actionDestination={"/locacao"}
        />
      </Card>
    );

  return (
    <>
      <Row gutter={24} align="middle">
        <Col sm={24}>
          <Row justify="space-between">
            <Typography.Title level={4}>{"Ordem de Locação"}</Typography.Title>
            <Typography.Title style={{ marginTop: 0 }} level={4}>
              <Tag>{`Nº ${ordemLocacao?.id}`}</Tag>
            </Typography.Title>
          </Row>
          <OrdemLocacaoReadOnly ordemLocacao={ordemLocacao} />
        </Col>
        <Divider style={{ marginTop: 0 }} />
        <Col sm={24}>
          <Typography.Title level={4}>Item de Locação</Typography.Title>
          <ItemLocacaoReadOnly
            itensLocacao={ordemLocacao?.itensLocacao}
            frequenciaLocacao={ordemLocacao?.frequenciaLocacao}
            quantidadeDias={ordemLocacao?.quantidadeDias}
          />
        </Col>
        <Divider />
        <Col sm={24}>
          <Typography.Title level={4}>
            Registro de Movimentação
          </Typography.Title>
          <RegistroMovimentacaoReadOnly
            registroMovimento={ordemLocacao?.registrosMovimento}
          />
        </Col>
      </Row>
    </>
  );
}
