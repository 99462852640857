import { Tag } from "antd";

export default function renderBadge(status?: string | undefined) {
  let variant;
  switch (status) {
    case "PENDENTE":
      variant = "secondary";
      break;
    case "ATIVO":
      variant = "green";
      break;
    case "FINALIZADO" || "CANCELADO":
      variant = "black";
      break;
    case "VENCIDO":
      variant = "danger";
      break;
    default:
      return status;
  }
  return <Tag color={variant}>{status}</Tag>;
}
