import { notification } from "antd";
import { Middleware, configureStore, isRejected } from "@reduxjs/toolkit";
import UsuarioReducer from "./Usuario.reducer";
import ClienteReducer from "./Cliente.reducer";
import ProdutoReducer from "./Produto.reducer";
import OrdemLocacaoReducer from "./OrdemLocacao.reducer";
import ComboboxReducer from "./Combobox.reducer";
import uiReducer from "./UI.slice";

const observeActions: Middleware = () => (next) => (action) => {
  if (isRejected(action)) {
    notification.error({
      message: action.error.message || "Houve um erro",
    });
  }

  next(action);
};

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    combobox: ComboboxReducer,
    usuario: UsuarioReducer,
    cliente: ClienteReducer,
    produto: ProdutoReducer,
    ordemLocacao: OrdemLocacaoReducer,
  },
  middleware: function (getDefaultMiddlewares) {
    return getDefaultMiddlewares().concat(observeActions);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
