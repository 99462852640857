import useBreadcrumb from "../../core/hooks/useBreadcrumb";
import usePageTitle from "../../core/hooks/usePageTitle";
import UsuarioForm from "../features/UsuarioForm";

export default function UsuarioCadastroView() {
  usePageTitle("Cadastro de usuário");
  useBreadcrumb("Usuário/Cadastro");

  return <UsuarioForm />;
}
