import { useCallback, useEffect } from "react";
import useUsuario from "../../core/hooks/useUsuario";
import UsuarioForm from "../features/UsuarioForm";
import { Card, Skeleton, notification } from "antd";
import { Usuario } from "../sdk/@types";
import dayjs from "dayjs";
import UsuarioService from "../sdk/services/Usuario.service";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import usePageTitle from "../../core/hooks/usePageTitle";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function UsuarioEdicaoView() {
  usePageTitle("Edição do usuário");
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { usuario, fetchUsuario, notFound } = useUsuario();
  useBreadcrumb(`Usuário/Edição/${usuario?.nome || usuario?.id}`);

  useEffect(() => {
    if (!isNaN(Number(params.id))) fetchUsuario(Number(params.id));
  }, [fetchUsuario, params.id]);

  const transformUserData = useCallback((usuario: Usuario.UsuarioResponse) => {
    return {
      ...usuario,
      dataNascimento: dayjs(usuario.dataNascimento),
      dataCadastro: dayjs(usuario.dataCadastro),
    };
  }, []);

  if (isNaN(Number(params.id))) return <Navigate to="/usuario" />;

  if (notFound) return <Card>usuário não encontrado</Card>;

  async function handleUsuarioUpdate(usuario: Usuario.UsuarioRequest) {
    await UsuarioService.update(Number(params.id), usuario).then(() => {
      navigate("/usuario");
      notification.success({
        message: "Usuário atualizado com sucesso",
      });
    });
  }

  if (!usuario) return <Skeleton />;

  return (
    <UsuarioForm
      usuario={transformUserData(usuario)}
      onUpdate={handleUsuarioUpdate}
    />
  );
}
