export default function formatCpfCnpj(cpfCnpj?: string) {
  if (!cpfCnpj) return cpfCnpj;

  const cleanedValue = cpfCnpj.replace(/\D/g, "");

  if (cleanedValue.length <= 11) {
    return formatCPF(cleanedValue);
  } else {
    return formatCNPJ(cleanedValue);
  }
}

const formatCPF = (cpf: string) => {
  return cpf
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
};

const formatCNPJ = (cnpj: string) => {
  return cnpj
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
};
