import { Layout, theme } from "antd";

const { Content } = Layout;

interface DefaultLayoutContentProps {
  children: React.ReactNode;
}

export default function DefaultLayoutContent(props: DefaultLayoutContentProps) {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Content>
      <div
        style={{
          padding: 24,
          minHeight: 280,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
          boxShadow:
            "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
        }}
      >
        {props.children}
      </div>
    </Content>
  );
}
