import { FormInstance } from "antd";
import { Form, Button, InputNumber, Card, Row, Col } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import ProdutoComboBoxComponent from "./ProdutoComboBoxComponent";
import { OrdemLocacao } from "../sdk/@types";

interface ItensDeLocacaoProps {
  form: FormInstance;
}

const ItensDeLocacaoComponent: React.FC<ItensDeLocacaoProps> = ({ form }) => {
  useEffect(() => {
    const itensLocacao: OrdemLocacao.ItemLocacaoRequest[] =
      form.getFieldValue("itensLocacao");
    if (!itensLocacao || itensLocacao.length === 0) {
      form.setFieldsValue({ itensLocacao: [{}] });
    }
  }, [form]);

  return (
    <Card title="Itens de Locação">
      <Form.List name="itensLocacao">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => {
              const { key, ...restField } = field;
              return (
                <Row
                  key={key}
                  gutter={24}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <Col xs={6}>
                    <Form.Item
                      {...restField}
                      name={[field.name, "quantidade"]}
                      rules={[
                        { required: true, message: "O campo é obrigatório" },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Quantidade"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={16}>
                    <Form.Item
                      {...restField}
                      name={[field.name, "idProduto"]}
                      rules={[
                        { required: true, message: "O campo é obrigatório" },
                      ]}
                    >
                      <ProdutoComboBoxComponent />
                    </Form.Item>
                  </Col>
                  <Col xs={2}>
                    <CloseOutlined
                      onClick={() => {
                        if (fields.length > 1) remove(field.name);
                      }}
                      style={{
                        color: fields.length === 1 ? "#d9d9d9" : "black",
                        cursor: fields.length > 1 ? "pointer" : "not-allowed",
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                block
              >
                Adicionar Item
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default ItensDeLocacaoComponent;
