import axios, { AxiosResponse } from "axios";
// import handleAxiosResponseError from "./utils/handleAxiosResponseError";
// import handleAxiosResponseSuccess from "./utils/handleAxiosResponseSuccess";

const API_SERVER_BASE_URL = process.env.REACT_APP_API_SERVER_BASE_URL;

const Http = axios.create();

class Service {
  protected static Http = Http;
  protected static getData = getData;
}

function getData<T>(res: AxiosResponse<T>) {
  return res.data;
}

Http.defaults.baseURL = `${API_SERVER_BASE_URL}/api/v1`;

// Http.interceptors.response.use(
//   handleAxiosResponseSuccess,
//   handleAxiosResponseError
// );

export default Service;
