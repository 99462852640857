import React, { useEffect } from "react";
import { Select } from "antd";
import useComboBox from "../../core/hooks/useComboBox";
import type { SelectProps } from "antd/lib/select";

interface ProdutoComboBoxComponentProps extends SelectProps<any> {}

export default function ProdutoComboBoxComponent(
  props: ProdutoComboBoxComponentProps
) {
  const { comboboxData, fetchingCombos, errorCombos, fetchComboboxData } =
    useComboBox("produtos");

  useEffect(() => {
    fetchComboboxData();
  }, [fetchComboboxData]);

  if (errorCombos) return <p>Erro: {errorCombos}</p>;

  return (
    <Select
      {...props}
      placeholder={props.placeholder || "Selecione um produto"}
      loading={fetchingCombos}
    >
      {comboboxData.map((combo) => (
        <Select.Option key={combo.value} value={combo.value}>
          {combo.description}
        </Select.Option>
      ))}
    </Select>
  );
}
