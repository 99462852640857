import { Col, Row } from "antd";
import UsuarioLista from "../features/UsuarioLista";
import usePageTitle from "../../core/hooks/usePageTitle";
import useBreadcrumb from "../../core/hooks/useBreadcrumb";

export default function UsuarioListaView() {
  usePageTitle("Consulta de usuário");
  useBreadcrumb("Usuário/Consulta");

  return (
    <Row>
      <Col xs={24}>
        <UsuarioLista />
      </Col>
    </Row>
  );
}
